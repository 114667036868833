<template>
  <div class="all-rule-list">
    <el-form
      :inline="true"
      :model="dataForm"
      class="form-content"
      @keyup.enter.native="getDataList()"
    >
      <el-form-item>
        <el-input style="width: 300px;" v-model="name" placeholder="输入成员名称关键字查询" clearable>
          <el-button slot="append" icon="el-icon-search" @click="getDataList()"></el-button>
        </el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="dialogVisible=true">添加</el-button>
        <!-- 				<el-button  type="primary"  @click="test()" >新增</el-button> -->
        <el-button
          type="danger"
          @click="deleteHandle()"
          :disabled="dataListSelections.length <= 0"
        >移除</el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="dataList"
      v-loading="dataListLoading"
      @selection-change="selectionChangeHandle"
      style="width: 100%;"
    >
      <el-table-column type="selection" width="50"></el-table-column>
      <el-table-column prop="docterName" min-width="280" label="成员名称"></el-table-column>
      <el-table-column prop="roleName" min-width="200" label="团队角色"></el-table-column>
      <el-table-column prop="phone" min-width="200" label="手机号"></el-table-column>
      <el-table-column prop="createTime" min-width="180" label="申请加入时间"></el-table-column>
      <el-table-column min-width="180" label="认证状态">
        <template slot-scope="scope">
          <span v-if="scope.row.auditStatus==null">未认证</span>
          <span v-else-if="scope.row.auditStatus==1">已通过</span>
          <span v-else-if="scope.row.auditStatus==2">待审核</span>
          <span v-else>未通过</span>
        </template>
      </el-table-column>
      <el-table-column min-width="180" label="审核状态">
        <template slot-scope="scope">
          <span v-if="scope.row.status==1">已加入</span>
          <span v-else-if="scope.row.status==3">待审核</span>
        </template>
      </el-table-column>
      <el-table-column fixed="right" header-align="right" align="right" min-width="200" label="操作">
        <template slot-scope="scope">
          <el-button
            style="color: #409EFF;"
            type="text"
            size="small"
            @click="gotoEdit(scope.row)"
          >编辑</el-button>
          <el-button
            style="color: #409EFF;"
            type="text"
            size="small"
            @click="gotoAudit(scope.row)"
          >审核</el-button>
          <!-- <el-button style="color: #409EFF;" type="text" size="small" @click="deleteHandle2(scope.row.id)" >移除</el-button> -->
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="sizeChangeHandle"
      @current-change="currentChangeHandle"
      :current-page="pageIndex"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="pageSize"
      :total="totalPage"
      layout="total, sizes, prev, pager, next, jumper"
    ></el-pagination>
    <el-dialog title="添加团队成员" :visible.sync="dialogVisible" width="800px">
      <el-form label-width="100px" class="dialog-form-con">
        <el-form-item :inline="true" label="成员" required>
          <el-select style="width:350px" v-model="userId" multiple filterable>
            <el-option
              v-for="item in userList"
              :key="item.organBusiness.id"
              :value="item.organBusiness.id"
              :label="item.organBusiness.name+'('+item.organBusiness.phone+')'"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="角色分配" required>
          <el-select style="width:350px" v-model="roleIds" multiple filterable>
            <el-option v-for="item in roleList" :key="item.id" :value="item.id" :label="item.name"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="addTeamMember" v-points>确定</el-button>
        <el-button @click="dialogVisible = false">取消</el-button>
      </div>
    </el-dialog>
    <el-dialog title="编辑团队成员" :visible.sync="dialogVisible2" width="800px">
      <el-form label-width="100px" class="dialog-form-con">
       <el-form-item :inline="true" label="成员" required>
          <el-select style="width:350px" v-model="userId" multiple disabled>
            <el-option
              v-for="item in userList"
              :key="item.organBusiness.id"
              :value="item.organBusiness.id"
              :label="item.organBusiness.name+'('+item.organBusiness.phone+')'"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="角色分配" required>
          <el-select style="width:350px" v-model="roleIds" multiple filterable>
            <el-option v-for="item in roleList" :key="item.id" :value="item.id" :label="item.name"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="editTeamMember" v-points>确定</el-button>
        <el-button @click="dialogVisible2 = false">取消</el-button>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="auditDialog" title="变更审核" width="800px">
      <el-form :model="form2" ref="audit" label-width="0" class="dialog-form-con">
        <div class="title mb20">请选择审核结果</div>
        <el-form-item
          prop="radio"
          :rules="{required: true, message: '审核结果不能为空', trigger: 'change'}"
        >
          <el-radio v-model="form2.radio" label="1">通过</el-radio>
          <el-radio v-model="form2.radio" label="4">不通过</el-radio>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="auditConfirm" v-points>确 定</el-button>
        <el-button @click="auditDialog = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Qs from "qs";
export default {
  components: {
    // AddOrUpdate
  },

  data() {
    return {
      teamsName: "",
      entityId: "",
      userId: "",
	  editId: "",
      content: "",
      jcbzList: [],
      userList: [],
      roleIds: "",
      dataForm: {
        teamsName: "",
        entityId: "",
        content: "",
      },
      name: "",
      dataList: [],
      roleList: [],
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
      dialogVisible: false,
      dialogVisible2: false,
      dataListLoading: false,
      auditDialog: false,
      dataListSelections: [],
      addOrUpdateVisible: false,
      form2: {
        radio: "",
      },
      person: "",
    };
  },

  computed: {},

  created() {
    this.teamsName = this.$route.query.teamsName;
    this.getDataList();
    this.getUserList();
    this.getRoleList();
    // this.getJcbzList()
  },

  methods: {
    gotoAudit(row) {
      this.auditDialog = true;
      this.form2.radio = "";
      this.person = row;
    },
    async auditConfirm() {
      const { data: res } = await this.$http({
        url: this.$http.adornUrl(`/teamsMember/updateStatusByIds`),
        method: "post",
        data: Qs.stringify({ ids: this.person.id, status: this.form2.radio }),
      });
      console.log(res);
      if (res.status) {
        this.$message.success("审核成功");
        this.auditDialog = false;
        this.getDataList();
      }
    },
    gotoEdit(row) {
      this.dataForm = row;

      this.dialogVisible2 = true;
	this.editId =row.id
      this.userId = [row.docterId];
      console.log(this.userId);
      this.roleIds = row.roleIds.split(",");
    },
    getRoleList() {
      let deptId = this.$cookie.get("hospitalId");
      // let deptId = JSON.parse(sessionStorage.getItem("userInfo")).deptId
      let obj = {
        intParam2: 1,
        intParam1: 999,

        intParam3: 2,
      };
      this.dataListLoading = true;
      this.$httpAes({
        url: this.$httpAes.adornUrl("/role/page"),
        method: "post",
        params: obj,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",

          token: this.$cookie.get("token"),
        },
      }).then(({ data }) => {
        this.roleList = data.data;
      });
    },
    // 获取数据列表
    getDataList() {
      // let deptId = JSON.parse(sessionStorage.getItem("userInfo")).deptId
      let obj = {
        intParam2: this.pageIndex,
        intParam1: this.pageSize,
        stringParam3: this.name,
        stringParam5: JSON.parse(localStorage.currentTeam).id,
        stringParam6: "1,3",
      };
      this.dataListLoading = true;
      this.$httpAes({
        url: this.$httpAes.adornUrl("/teamsMember/list"),
        method: "post",
        data: obj,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",

          token: this.$cookie.get("token"),
        },
      }).then(({ data }) => {
        this.dataList = data.data;
        this.totalPage = data.totalCount;

        this.dataListLoading = false;
      });
    },
    getUserList(data) {
      let hospitalId = this.$cookie.get("hospitalId");
      let deptId = "";
      if (!data) {
        deptId = hospitalId;
      } else if (!data.hasOwnProperty("deptId")) {
        deptId = data.id;
      } else {
        console.log("有deptId", data);

        deptId = data.deptId;
      }

      this.dataListLoading = true;
      this.$httpAes({
        url: this.$httpAes.adornUrl(
          "/organBusiness/backUserList?pageSize=999&pageNo=1&deptId=" 
        ),
		data:{
			intParam1:999,
			stringParam4:'',
			intParam2:1,
		},
        method: "post",
      }).then(async ({ data }) => {
        if (data.status) {
          this.userList = data.data;
        } else {
          this.userList = [];
        }
        this.dataListLoading = false;
      });
    },
    //基础病种
    getJcbzList() {
      let deptId = this.$cookie.get("hospitalId");
      // let deptId = JSON.parse(sessionStorage.getItem("userInfo")).deptId
      let obj = {
        pageNo: 1,
        pageSize: 9999,
        type: "基础病种",
      };
      this.dataListLoading = true;
      this.$httpAes({
        url: this.$httpAes.adornUrl("/dict/list"),
        method: "post",
		data: {
		  stringParam1: "基础病种",
		},
        params: obj,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",

          token: this.$cookie.get("token"),
        },
      }).then(({ data }) => {
        this.jcbzList = data.data;
      });
    },
    addTeamMember() {
      var that = this;
      var that = this;
      if (this.userId == "") {
        this.$message.warning("请选择成员");
        return;
      }
      if (this.roleIds == "") {
        this.$message.warning("请选择角色");
        return;
      }
      var params = {
        teamsId: JSON.parse(localStorage.currentTeam).id,
        docterId: this.userId,

        roleIds: this.roleIds,

        entityId: JSON.parse(localStorage.currentTeam).entityId,
      };
      let arr = [];
      this.userId.forEach((item) => {
        let param = {
          teamsId: JSON.parse(localStorage.currentTeam).id,
          docterId: item,

          roleIds: this.roleIds.join(","),

          entityId: JSON.parse(localStorage.currentTeam).entityId,
        };
        arr.push(param);
      });

      that
        .$http({
          url: that.$http.adornUrl("/teamsMember/add"),
          method: "post",
          // data: {teamsMembers:JSON.stringify(arr)},
          data: JSON.stringify(arr),
          headers: {
            "Content-Type": "application/json",

            token: this.$cookie.get("token"),
          },
        })
        .then(({ data }) => {
          if (data && data.status) {
            that.$message({
              message: data.msg,
              type: "success",
              duration: 1500,
              onClose: () => {
                that.dialogVisible = false;
                that.userId = "";
                that.roleIds = "";

                that.getDataList();
              },
            });
          } else {
            that.$message.error(data.msg);
          }
        });
    },
    editTeamMember() {
      var that = this;
      var params = {
        teamsId: JSON.parse(localStorage.currentTeam).id,
        docterId:  this.dataForm.docterId ,
		
id:this.editId,
        roleIds: this.roleIds.join(","),

        entityId: JSON.parse(localStorage.currentTeam).entityId,
      };
      // let arr = [];
//       this.userId.forEach((item) => {
//         let param = {
//           teamsId: JSON.parse(localStorage.currentTeam).id,
//           docterId: item,
// id:this.editId,
//           roleIds: this.roleIds.join(","),

//           entityId: JSON.parse(localStorage.currentTeam).entityId,
//         };
//         arr.push(param);
//       });

      that
        .$http({
          url: that.$http.adornUrl("/teamsMember/update"),
          method: "post",
          // data: {teamsMembers:JSON.stringify(arr)},
          data: Qs.stringify(params),
          headers: {
            // "Content-Type": "application/json",

            token: this.$cookie.get("token"),
          },
        })
        .then(({ data }) => {
          if (data && data.status) {
            that.$message({
              message: data.msg,
              type: "success",
              duration: 1500,
              onClose: () => {
                that.dialogVisible = false;
				  that.dialogVisible2 = false;
				
                that.userId = "";
                that.roleIds = "";

                that.getDataList();
              },
            });
          } else {
            that.$message.error(data.msg);
          }
        });
    },
    editTeam() {
      var that = this;

      that
        .$httpAes({
          url: that.$httpAes.adornUrl("/teamsManage/update"),
          method: "post",
          params: that.dataForm,
        })
        .then(({ data }) => {
          if (data && data.status) {
            that.$message({
              message: data.msg,
              type: "success",
              duration: 1500,
              onClose: () => {
                that.dialogVisible2 = false;

                that.getDataList();
              },
            });
          } else {
            that.$message.error(data.msg);
          }
        });
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val;
      this.pageIndex = 1;
      this.getDataList();
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val;
      this.getDataList();
    },
    // 多选
    selectionChangeHandle(val) {
      this.dataListSelections = val;
    },

    gotoRoleInfo(info) {
      this.$router.push({
        path: "/role/role-info",
        query: { id: info.id },
      });
    },
    gotoRoleEdit(info) {
      this.$router.push({
        path: "/role/role-edit",
        query: { id: info.id },
      });
    },

    // 删除
    deleteHandle(id) {
      var userIds = id
        ? [id]
        : this.dataListSelections.map((item) => {
            return item.id;
          });
      var ids = {
        userId: this.$cookie.get("userId"),
        stringParam1: userIds.join(","),
      };

      this.$confirm(`确定进行[${id ? "删除" : "批量删除"}]操作?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$httpAes({
            url: this.$httpAes.adornUrl("/teamsMember/delete"),
            method: "post",
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",

              token: this.$cookie.get("token"),
            },
            data: ids,
          }).then(({ data }) => {
            if (data.status) {
              this.$message({
                message: "操作成功",
                type: "success",
                duration: 1500,
                onClose: () => {
                  this.getDataList();
                },
              });
            } else {
              this.$message.error(data.msg);
              this.getDataList();
            }
          });
        })
        .catch(() => {});
    },
    deleteHandle2(id) {
      var userIds = id
        ? [id]
        : this.dataListSelections.map((item) => {
            return item.id;
          });
      var ids = {
        userId: this.$cookie.get("userId"),
        stringParam1: userIds.join(","),
      };

      this.$confirm(`确定进行[${id ? "删除" : "批量删除"}]操作?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$httpAes({
            url: this.$httpAes.adornUrl("/teamsMember/delete"),
            method: "post",
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",

              token: this.$cookie.get("token"),
            },
            data:ids,
          }).then(({ data }) => {
            if (data.status) {
              this.$message({
                message: "操作成功",
                type: "success",
                duration: 1500,
                onClose: () => {
                  this.getDataList();
                },
              });
            } else {
              this.$message.error(data.msg);
              this.getDataList();
            }
          });
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss">
.all-rule-list {
  .search-top {
    padding-bottom: 20px;
    overflow: hidden;

    .search-btn {
      font-size: 20px;
      cursor: pointer;
    }
  }

  .el-dialog {
    width: 600px;
  }
}
</style>